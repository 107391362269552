/* eslint-disable prettier/prettier */
export const MessageBox = () => {
    const messageText = 'Want to Send Arrival Info to Guest? Go to Hiisi MSG tab.'

    return (
        <div>
            <p>
                {messageText}
            </p>
        </div>
    )
}