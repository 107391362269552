import { KeyStatus, KeyStatusValue } from "../models/PinCodeData";

export const getKeyStatusText = (keyStatusData?: KeyStatus[]): string => {
  const status = keyStatusData?.filter((s) => s.lockSystem === "LivionKey");
  if (status && status.length) {
    const value = status[0].value;
    if (value === "Unknow") {
      return "Unknown";
    }
    if (value === "Fetched" || value === "NotReturned") {
      return "Key not inside";
    }
    return "Key inside";
  }
  return "Unknown";
};

export const getLivionKeyStatus = (
  keyStatusData?: KeyStatus[]
): KeyStatusValue | undefined => {
  const status = keyStatusData?.filter((s) => s.lockSystem === "LivionKey");
  if (status && status.length) {
    return status[0].value;
  }
};

export const isLivionKey = (keyStatusData?: KeyStatus[]): boolean => {
  const status = keyStatusData?.filter((s) => s.lockSystem === "LivionKey");
  return status !== undefined && status.length > 0;
};
