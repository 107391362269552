import "../styles/headerStyles.css";
// import Logo from '../img/LOGO.png'

export const Header = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const logo = require("../img/LOGO.png");
  return (
    <div className="header">
      <p className="header header-text">HIISI KEYS</p>
      <img src={logo} className="logo" alt="Hiisi Homes Logo"></img>
    </div>
  );
};
