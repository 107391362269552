/* eslint-disable prettier/prettier */
import axios, { AxiosRequestConfig } from "axios";
import { ReactNode, useEffect, useState } from "react";
import {
  BackUpCodes,
  CreatedBy,
  ErrorMessageData,
  KeyStatus,
  LivionFetchReturnData,
  PinCode,
  PinCodeRawData,
  PinCodeRawDataAxios,
} from "../models/PinCodeData";
import { BackupCodesField } from "./BackupCodesField";
import KeyStatusIndicator from "./KeyStatusIndicator";
import LoadingSpinner from "./LoadingSpinner";

import useModal from "../hooks/useModal";
import styles from "../styles/livionDataCardStyles.module.css";
import Modal from "./Modal";
import { PinCodeCard } from "./PinCodeCard";
import { PinCodeInfoField } from "./PinCodeInfoField";
import { PinCodeActions, PinCodesMenu } from "./PinCodesMenu";

type LivionDataCardProps = {
  reservationId: string;
  secret: string;
  apiKey: string;
  baseUrl: string;
  subjectId: string;
};

export const LivionDataCard = (props: LivionDataCardProps) => {
  const headers: HeadersInit = {
    accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    secret: props.secret,
    "x-api-key": props.apiKey,
  };

  const [primaryPinCode, setPrimaryPinCode] = useState<PinCode | undefined>();
  const [secondaryPinCode, setSecondaryPinCode] = useState<
    PinCode | undefined
  >();

  const [keyStatusData, setKeyStatusData] = useState<KeyStatus[]>([]);
  const [backupCodesData, setBackupCodesData] = useState<BackUpCodes>({
    lockSystem: "",
    codes: ["Not set"],
  });
  const [pinCodeInfo, setPinCodeInfo] = useState<CreatedBy>({
    subjectId: "",
    firstName: "Not Found",
    lastName: "Not Found",
    email: "Not Found",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [pinExists, setPinExists] = useState<boolean>(false);
  const { isOpenModal, toggleModal } = useModal();
  const [modalItem, setModalItem] = useState<ReactNode>();

  const updatePinCodes = (pinData: PinCodeRawData | PinCodeRawDataAxios) => {
    setPrimaryPinCode(pinData.pinCodes[0]);
    if (pinData.pinCodes.length > 1) {
      setSecondaryPinCode(pinData.pinCodes[1]);
    }
  };

  // TODO: exhaustive-deps issue should be fixed
  useEffect(() => {
    handleDataFetching();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setAlertMessage = (errorData: ErrorMessageData) => {
    const supportMessage = `Please contact support if this problem persists.`;
    const errorMessage = `\n${errorData.statusCode} ${errorData.message}`;
    if (errorData.statusCode >= 500) {
      return `Server error. ${supportMessage} RESPONSE: [${errorMessage}]`;
    }
    if (errorData.statusCode === 400) {
      return `Validation errors in the request body. Cannot fetch PIN data. ${supportMessage} RESPONSE: [${errorMessage}]`;
    }
    if (errorData.statusCode === 405) {
      return `Reservation has no unit assigned or assigned unit has no lock devices. RESPONSE: [${errorMessage}]`;
    }
    if (errorData.statusCode === 409) {
      return `Pin code already exists for Reservation. RESPONSE: [${errorMessage}]`;
    }
    return `Unknown error. ${supportMessage} RESPONSE: [${errorMessage}]`;
  };

  const handleDataFetching = async () => {
    setIsLoading(true);
    const getPinCodeUrl = `${props.baseUrl}/pin-codes/${props.reservationId}`;
    const getKeyStatusUrl = `${props.baseUrl}/key-status/${props.reservationId}`;
    const getBackupCodesUrl = `${props.baseUrl}/backup-codes/${props.reservationId}`;
    try {
      const responseData: LivionFetchReturnData | undefined =
        await fetchLivionData([
          getPinCodeUrl,
          getKeyStatusUrl,
          getBackupCodesUrl,
        ]);
      if (!responseData) {
        throw new Error("No PIN found.");
      } else {
        updatePinCodes(responseData.pinData);
        setPinCodeInfo(responseData.pinData.createdBy || {subjectId: '', firstName: '', lastName: 'Automation', email: ''});
        setKeyStatusData(responseData.keyStatusData);
        if (responseData.backupCodesData) {
          setBackupCodesData({
            lockSystem: responseData.backupCodesData[0].lockSystem || '',
            codes: responseData.backupCodesData[0].codes || [],
          })
        } else {
          setBackupCodesData({
            lockSystem: responseData.pinData.pinCodes[0].lockSystem,
            codes: []
          })
        }
        setPinExists(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLivionData = async (
    urls: Array<string>
  ): Promise<LivionFetchReturnData | undefined> => {
    let returnData: LivionFetchReturnData | undefined = undefined;
    const request: AxiosRequestConfig = {
      method: "GET",
      headers: headers,
    };
    const requests = urls.map(async (url) => {
      try {
        const response = await axios.get(url, request);
        return response.data;
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          return undefined;
        }
        throw error;
      }
    });
  
    try {
      const data = await Promise.all(requests);
  
      returnData = {
        pinData: data[0],
        keyStatusData: data[1],
        backupCodesData: data[2],
      };
    } catch (error) {
      console.error(error);
    }
  
    return returnData;
  };

  // const fetchLivionData = async (
  //   urls: Array<string>
  // ): Promise<LivionFetchReturnData | undefined> => {
  //   let returnData: LivionFetchReturnData | undefined = undefined;
  //   const request: AxiosRequestConfig = {
  //     method: "GET",
  //     headers: headers,
  //   };
  //   const requests = urls.map((url) => axios.get(url, request));
  //   await axios
  //     .all(requests)
  //     .then(
  //       axios.spread((...allData) => {
  //         const data = allData.map((data) => data.data);
  //         returnData = {
  //           pinData: data[0],
  //           keyStatusData: data[1],
  //           backupCodesData: data[2],
  //         };
  //       })
  //     )
  //     .catch((error) => {
  //       console.error(error);
  //     });
  //   return returnData;
  // };

  const createPinCode = async (): Promise<PinCodeRawData | undefined> => {
    const url = `${props.baseUrl}/pin-codes`;
    const request: RequestInit = {
      method: "POST",
      headers: headers,
      mode: "cors",
      body: JSON.stringify({
        id: props.reservationId,
        subjectId: props.subjectId,
      }),
    };
    try {
      const response = await fetch(url, request);
      if (response.status === 201) {
        setPinExists(true);
        return await response.json();
      } else {
        const errorData: ErrorMessageData = await response.json();
        alert(setAlertMessage(errorData));
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const deletePinCode = async (): Promise<boolean> => {
    const url = `${props.baseUrl}/pin-codes/${props.reservationId}`;
    const request: RequestInit = {
      method: "DELETE",
      headers: headers,
      mode: "cors",
    };
    try {
      const response: Response = await fetch(url, request);
      if (response.status === 200) {
        console.log(
          `Succesfully deleted Pin for reservation ${props.reservationId}.`
        );
        return true;
      } else {
        throw new Error(
          `Failed to delete Pin for reservation ${props.reservationId}.`
        );
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const keyNotPresent = (): boolean => {
    const livionKey = keyStatusData.filter((s) => s.lockSystem === "LivionKey");
    if (livionKey.length) {
      return (
        livionKey[0].value === "Fetched" || livionKey[0].value === "NotReturned"
      );
    }
    return false;
  };

  const confirm = (
    message: string,
    confirmCondition?: () => boolean
  ): boolean => {
    if (confirmCondition) {
      if (confirmCondition()) {
        return window.confirm(message);
      }
      return true;
    } else {
      return window.confirm(message);
    }
  };

  const handlePincodeCreation = async (): Promise<void> => {
    if (pinExists) {
      if (
        !confirm(
          "Key is not present. Are sure you want to create PIN code?",
          keyNotPresent
        )
      ) {
        return;
      }
      if (
        confirm(
          "Pin already exists for reservation. Do you want to update Pin?"
        )
      ) {
        setIsLoading(true);
        if (await deletePinCode()) {
          const pinData = await createPinCode();
          if (pinData) {
            updatePinCodes(pinData);
          }
        } else {
          alert(`Failed to delete Pin for reservation ${props.reservationId}.`);
        }
      }
    } else {
      if (
        !confirm(
          "Key is not present. Are sure you want to create PIN code?",
          keyNotPresent
        )
      ) {
        return;
      }

      setIsLoading(true);
      const pinData = await createPinCode();
      if (pinData) {
        updatePinCodes(pinData);
      }
    }
    handleDataFetching();
  };

  const handleBackupCodesRotation = () => {
    if (pinExists) {
      if (
        confirm(
          "This action will create new Backup Pincodes for this reservation. Continue?"
        )
      ) {
        rotateBackupCodes();
      }
    }
  };

  const rotateBackupCodes = async (): Promise<void> => {
    const url = `${props.baseUrl}/backup-codes/${props.reservationId}`;
    const request: RequestInit = {
      method: "PUT",
      headers: headers,
      mode: "cors",
    };
    const response: Promise<BackUpCodes[]> = await fetch(url, request)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
      });
    setBackupCodesData((await response)[0]);
  };

  // const handleResendLockData = async () => {
  //   if (confirm("Do you want to resend pincode to customer?")) {
  //     if (await resendLockDataToGuest()) {
  //       alert("Pincode was succesfully sent to customer.");
  //     } else {
  //       alert("Error: Could not send pincode to customer.");
  //     }
  //   }
  // };

  const handleDeletePinCode = async () => {
    if (
      confirm("This will delete existing pincode, do you want to continue?")
    ) {
      setIsLoading(true);
      if (await deletePinCode()) {
        setPinExists(false);
        setPrimaryPinCode(undefined);
        setSecondaryPinCode(undefined);
        setKeyStatusData([]);
        setIsLoading(false);
        alert("Pincode was succesfully deleted.");
      } else {
        alert(
          "Failed to delete pincode. Please use the lock system application."
        );
      }
    }
    setIsLoading(false);
  };

  // const resendLockDataToGuest = async (): Promise<boolean> => {
  //   const url = `${props.baseUrl}/pin-codes/${props.reservationId}/send-notification`;
  //   const request: RequestInit = {
  //     method: "PUT",
  //     headers: headers,
  //     mode: "cors",
  //   };
  //   try {
  //     const response: Response = await fetch(url, request);
  //     if (response.status === 200) {
  //       console.log(
  //         `Succesfully resent lock data for reservation ${props.reservationId}.`
  //       );
  //       return true;
  //     } else {
  //       throw new Error(
  //         `Failed to resend lock data for reservation ${props.reservationId}.`
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     return false;
  //   }
  // };

  const showBackupCodes = () => {
    toggleModal();
    setModalItem(<BackupCodesField backupCodes={backupCodesData.codes} />);
  };

  const showPinInfo = () => {
    toggleModal();
    setModalItem(<PinCodeInfoField createdBy={pinCodeInfo} pinData={primaryPinCode}/>);
  };

  const handleDropDownAction = (key: PinCodeActions): void => {
    switch (key) {
      case "create-new-pincode":
        handlePincodeCreation();
        break;

      case "view-pincode-info":
        showPinInfo();
        break;

      case "show-backupcodes":
        showBackupCodes();
        break;

      case "rotate-backupcodes":
        handleBackupCodesRotation();
        break;

      // case "resend-pin-to-guest":
      //   handleResendLockData();
      //   break;

      case "delete-pincode":
        handleDeletePinCode();
        break;
    }
  };

  const dataCard: JSX.Element = (
    <div className={styles.datacardCard}>
      <Modal isOpen={isOpenModal} toggle={toggleModal} children={modalItem} />
      <div className={styles.datacardHeader}>
        <PinCodesMenu
          primaryPinCode={primaryPinCode}
          secondaryPinCode={secondaryPinCode}
          handleDropDownAction={handleDropDownAction}
        />
        <KeyStatusIndicator keyStatusData={keyStatusData} />
      </div>
      <div className={styles.pincodecardContainer}>
        <PinCodeCard
          pinCodeData={primaryPinCode}
          keyStatusData={keyStatusData}
        />
        {secondaryPinCode && (
          <PinCodeCard
            pinCodeData={secondaryPinCode}
            keyStatusData={keyStatusData}
          />
        )}
      </div>
      {/* <BasicButton clickAction={handlePincodeCreation} /> */}
    </div>
  );

  return (
    <div className={styles.datacardContainer}>
      {isLoading ? <LoadingSpinner /> : dataCard}
    </div>
  );
};
