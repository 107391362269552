import "../styles/basicButtonStyles.css";

type Props = {
  clickAction: () => any;
  text: string;
};

export const BasicButton = (props: Props) => {
  return (
    <div>
      <button className="button-basic" onClick={props.clickAction}>
        {props.text}
      </button>
    </div>
  );
};
