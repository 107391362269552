import MenuIcon from "@mui/icons-material/Menu";
import "../styles/dropDownMenuStyles.css";

export type DropDownItem<T> = {
  key: T;
  name: string;
  action: (key: T) => void;
};

type DropDownProps<T> = {
  links: Array<DropDownItem<T>>;
};

export const DropDownMenu = <T,>(props: DropDownProps<T>) => {
  const createLink = (link: DropDownItem<T>) => {
    return (
      <a key={link.key as string} onClick={() => link.action(link.key)}>
        {link.name}
      </a>
    );
  };

  const mapAndCreateLinks = () => {
    // return ['322365626', '235236532'].map(code => createCodeField(`${code}#`))
    return props.links.map((link) => createLink(link));
  };

  return (
    <div className="dropdown">
      <div className="dropbtn">
        <MenuIcon fontSize="large" sx={{ color: "#000000" }} />
      </div>
      <div className="dropdown-content">{mapAndCreateLinks()}</div>
    </div>
  );
};
