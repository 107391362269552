import { Route, Routes } from "react-router-dom";
import { LockView } from "./LockView";
import NoContent from "./NoContent";

export const Router = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<NoContent />} />
        <Route path="/lock-management" element={<LockView />} />
      </Routes>
    </div>
  );
};
