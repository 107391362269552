import { useState } from "react";

export default function useModal() {
  const [isOpenModal, setisOpen] = useState(false);

  const toggleModal = () => {
    setisOpen(!isOpenModal);
  };

  return {
    isOpenModal,
    toggleModal,
  };
}
