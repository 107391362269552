import { useState } from "react";
import { KeyStatus } from "../models/PinCodeData";
import "../styles/keyStatusStyles.css";
import {
  getKeyStatusText,
  getLivionKeyStatus,
  isLivionKey,
} from "../utils/livionKeyUtils";

type Props = {
  keyStatusData: KeyStatus[];
};

const KeyStatusIndicator = (props: Props) => {
  const green =
    "linear-gradient(rgba(150, 255, 150, 0.5),rgba(77, 255, 77, 0.9))";
  const red =
    "linear-gradient(rgba(255, 150, 150, 0.7),rgba(255, 77, 77, 0.9))";
  const greenKeyStatusValues: Array<string> = [
    "Added",
    "Returned",
    "NotFetched",
  ];
  const indicatorColor = greenKeyStatusValues.find(
    (value) => value === getLivionKeyStatus(props.keyStatusData)
  )
    ? green
    : red;

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div className="keystatus-container">
      {isLivionKey(props.keyStatusData) && (
        <div
          className="keystatus-indicator"
          style={
            showTooltip
              ? { backgroundColor: "white" }
              : { backgroundImage: `${indicatorColor}` }
          }
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {showTooltip
            ? `Key Status:  ${getKeyStatusText(props.keyStatusData)}`
            : ""}
        </div>
      )}
    </div>
  );
};

export default KeyStatusIndicator;
