import "../styles/backupCodesStyles.css";

type Props = {
  backupCodes: Array<string>;
};

export const BackupCodesField = (props: Props) => {
  const createCodeField = (text: string) => {
    return <div>{text}</div>;
  };

  const createParagraphs = () => {
    return props.backupCodes.map((code) => createCodeField(`${code}#`));
  };

  return (
    <div className="backupcodes-container">
      <h3>Backup Codes:</h3>
      <div className="backupcodes-items">
        <div className="backupcodes-codes">{createParagraphs()}</div>
      </div>
    </div>
  );
};
