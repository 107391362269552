/* eslint-disable prettier/prettier */
import React from "react";

import "../styles/lockViewStyles.css";
import { Header } from "./Header";
import { LivionDataCard } from "./LivionDataCard";
import { MessageBox } from "./MessageBox";

export const LockView: React.FC = () => {
  const getReservationId = (): string => {
    const reservationIdValue = new URLSearchParams(window.location.search).get(
      "reservationId"
    );
    return reservationIdValue != null ? reservationIdValue : "";
  };

  const getBaseUrl = (): string => {
    return process.env.REACT_APP_API_BASE_URL !== undefined
      ? process.env.REACT_APP_API_BASE_URL
      : "";
  };

  const getApiKey = (): string => {
    const apikey = new URLSearchParams(window.location.search).get("apikey");
    return apikey != null ? apikey : "";
  };

  const getSecret = (): string => {
    const secret = new URLSearchParams(window.location.search).get("secret");
    return secret != null ? secret : "";
  };

  const getSubjectId = (): string => {
    const subjectId = new URLSearchParams(window.location.search).get(
      "subjectId"
    );
    return subjectId != null ? subjectId : "";
  };

  return (
    <div className="lockview-container">
      <Header />
      <div className="datacards">
        <LivionDataCard
          reservationId={getReservationId()}
          secret={getSecret()}
          apiKey={getApiKey()}
          baseUrl={getBaseUrl()}
          subjectId={getSubjectId()}
        />
      </div>
      <MessageBox/>
    </div>
  );
};
