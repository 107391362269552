/* eslint-disable prettier/prettier */
import { CreatedBy, PinCode } from "../models/PinCodeData";
import { formatPinCodeDate } from "../utils/dateUtils";

type PinCodeInfoFieldProps = {
  createdBy: CreatedBy;
  pinData?: PinCode;
};

export const PinCodeInfoField = (props: PinCodeInfoFieldProps) => {
  const formatName = (first: string, last: string): string => {
    return first !== last ? `${first} ${last}` : last;
  };

  const handleCreatedAtDate = (): string => {
    if (props.pinData) {
      return formatPinCodeDate(props.pinData.createdAt)
    }
    return ''
  }

  return (
    <div className="pincodeinfo-container">
      <h3>PIN Created By</h3>
      <p className="pincodeinfo-text name">
        {formatName(props.createdBy.firstName, props.createdBy.lastName)}
      </p>
      <p className="pincodeinfo-text email">{props.createdBy.email}</p>
      <p className="pincodeinfo-text created">{handleCreatedAtDate()}</p>
    </div>
  );
};
