import { PinCode } from "../models/PinCodeData";
import { DropDownItem, DropDownMenu } from "./DropDownMenu";

export type PinCodeActions =
  | "create-new-pincode"
  | "view-pincode-info"
  | "show-backupcodes"
  | "rotate-backupcodes"
  // | "resend-pin-to-guest"
  | "delete-pincode";

export interface PinCodesMenuProps {
  primaryPinCode?: PinCode;
  secondaryPinCode?: PinCode;
  handleDropDownAction: (key: PinCodeActions) => void;
}

export const PinCodesMenu = (props: PinCodesMenuProps) => {
  const { primaryPinCode, secondaryPinCode, handleDropDownAction } = props;

  const resolveDropDownItems = (): Array<DropDownItem<PinCodeActions>> => {
    let items: Array<DropDownItem<PinCodeActions>> = [
      {
        key: "create-new-pincode",
        name: "Create New Pincode",
        action: handleDropDownAction,
      },
      {
        key: "delete-pincode",
        name: "Delete PIN",
        action: handleDropDownAction,
      },
    ];

    if (
      (primaryPinCode && primaryPinCode.lockSystem === "LivionKey") ||
      (secondaryPinCode && secondaryPinCode.lockSystem === "LivionKey") ||
      (secondaryPinCode && secondaryPinCode.lockSystem === "")
    ) {
      const livionKeyItems: Array<DropDownItem<PinCodeActions>> = [
        {
          key: "view-pincode-info",
          name: "View Pincode Info",
          action: handleDropDownAction,
        },
        {
          key: "show-backupcodes",
          name: "Show Backupcodes",
          action: handleDropDownAction,
        },
        {
          key: "rotate-backupcodes",
          name: "Rotate Backupcodes",
          action: handleDropDownAction,
        },
      ];
      items = [...items, ...livionKeyItems];
    }

    // if (primaryPinCode || secondaryPinCode) {
    //   items.push({
    //     key: "resend-pin-to-guest",
    //     name: "Resend PIN to Guest",
    //     action: handleDropDownAction,
    //   });
    // }
    return items;
  };

  return <DropDownMenu links={resolveDropDownItems()} />;
};
