export const formatPinCodeDate = (dateInput: string): string => {
  try {
    if (dateInput !== "-") {
      const date = new Date(dateInput);
      const year = date.getFullYear();
      const month = formatDateNumbersToIncludeZero(date.getMonth() + 1);
      const day = formatDateNumbersToIncludeZero(date.getDate());
      const hours = formatDateNumbersToIncludeZero(date.getHours());
      const minutes = formatDateNumbersToIncludeZero(date.getMinutes());
      const offset = `${date.getTimezoneOffset() / -60}:00`;
      return `${day}/${month}/${year} ${hours}:${minutes}+${offset}`;
    } else {
      throw new Error("Invalid date");
    }
  } catch (error: any) {
    if (error.message !== "Invalid date") {
      console.log(error);
    }
    return dateInput;
  }
};

const formatDateNumbersToIncludeZero = (dateNumber: number): string => {
  return `${dateNumber < 10 ? "0" : ""}${dateNumber}`;
};
